import React, { useState, useEffect } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBox, MessageBox } from '../../../components'
import { MDBDataTableV5 } from 'mdbreact'
import { allEnterpriseList } from '../../../actions/adminActions/enterpriseActions/allEnterpriseList'
import { updateEnterprise } from '../../../actions/adminActions/enterpriseActions/updateEnterprise'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { allProductCategory } from '../../../actions/adminActions/productActions/productCategory'
import { listProducts } from '../../../actions/adminActions/productActions/allProduct'
import Select from 'react-select'
import SweetAlert from 'react-bootstrap-sweetalert'
import { UPDATE_ENTERPRISE_RESET } from '../../../constants/adminConstants/enterpriseConstant'
import { allServiceCategory } from '../../../actions/adminActions/serviceActions/allServiceCategory'

export default function AllEnterprisePage() {
  const [alert, setAlert] = useState()
  const [datatable, setDatatable] = useState({})
  const [open, setOpen] = useState(false)
  const [itemId, setItemId] = useState('')
  const [name, setName] = useState('')
  const [category, setProductCategory] = useState('')
  const [service, setServiceCategory] = useState('')
  const [duration, setDuration] = useState('')
  const [list, setinputList] = useState([
    { EOPItem: '', quantity: '', serviceCategory: '', product: [], notes: '', UoM: '' },
  ])

  const allEnterprise = useSelector((state) => state.allEnterprise)
  const { loading, error, enterprises } = allEnterprise

  const productsList = useSelector((state) => state.productsList)
  const { loading: loadingProduct, products } = productsList

  const productCategory = useSelector((state) => state.productCategory)
  const { loading: loadingCategory, categorylist } = productCategory

  const enterpriseUpdated = useSelector((state) => state.enterpriseUpdated)
  const { loading: loadingUpdate, updatedEnterprise, error: updateError } = enterpriseUpdated

  const serviceCategory = useSelector((state) => state.serviceCategory)
  const { loading: loadingServices, categorylist: services } = serviceCategory

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(allEnterpriseList())
    dispatch(listProducts())
    dispatch(allProductCategory())
    dispatch(allServiceCategory())
  }, [updatedEnterprise])

  useEffect(() => {
    if (enterprises) {
      setDatatable({
        columns: [
          {
            label: 'Name',
            field: 'name',
            width: 200,
          },
          {
            label: 'Duration',
            field: 'duration',
            width: 400,
          },
          {
            label: 'Product Category',
            field: 'category',
            width: 400,
          },

          {
            label: 'Date Created',
            field: 'date',
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: enterprises.map((item) => ({
          name: item.name,
          duration: item.duration,
          category: item.category && item.category.name ? item.category.name : '',
          date: moment(item.createdAt).format('Do MMMM YYYY,  h:mm a'),
          action: (
            <div>
              <button
                onClick={() => editEnterprise(item)}
                style={{ marginRight: '15px' }}
                className="btn btn-sm btn-primary"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Manage"
              >
                Manage
              </button>
            </div>
          ),
        })),
      })
    }
  }, [enterprises])

  useEffect(() => {
    if (updatedEnterprise) {
      setItemId()
      setOpen(false)
      setName()
      setDuration()
      setProductCategory()
      setinputList([
        { EOPItem: '', quantity: '', product: [], notes: '', UoM: '' },
      ])
      setTimeout(() => {
        dispatch({ type: UPDATE_ENTERPRISE_RESET })
      }, 2500)
    }
  }, [updatedEnterprise])


  const editEnterprise = (item) => {
    setItemId(item._id)
    setOpen(true)
    setName(item.name)
    setDuration(item.duration)
    setProductCategory(item && item.category && item.category._id)
    setinputList(item.EOP)
  }


  const handleinputchange = (e, index) => {
    const { name, value } = e
    const listItem = [...list]
    listItem[index][name] = value
    setinputList(listItem)
  }

  const handleremove = (index) => {
    const listItem = [...list]
    listItem.splice(index, 1)
    setinputList(listItem)
  }

  const handleAddClick = (e) => {
    e.preventDefault()
    const emptyInput = list.some(
      (item) =>
        item.EOPItem === '' ||
        item.quantity === '' ||
        item.notes === '' ||
        item.UoM === '' ||
        item.serviceCategory === ''
      // item.product.length === 0,
    )

    if (!emptyInput) {
      setinputList((newInput) => [
        ...newInput,
        { EOPItem: '', quantity: '', serviceCategory: '', product: [], notes: '', UoM: '' },
      ])
    } else {
      // Handle case where empty fields exist in the existing items
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Field(s)"
          onConfirm={() => setAlert()}
          onCancel={() => setAlert()}
        >
          Please fill in all fields before adding a new item.
        </SweetAlert>,
      )
    }
  }

  const handleUpdate = (e) => {
    e.preventDefault()

    if (!name || !duration || !category) {
      // Handle case where empty fields exist in the existing items
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Field(s)"
          onConfirm={() => setAlert()}
          onCancel={() => setAlert()}
        >
          Please fill in all fields before adding a new item.
        </SweetAlert>,
      )
    } else {
      const inputList =
        Array.isArray(list) &&
        list.length > 0 &&
        list.map((item) => ({
          ...item,
          product: item.product.map((productItem) => productItem),
        }))
      dispatch(updateEnterprise(itemId, name, duration, category, inputList))
    }
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">All Enterprise</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Enterprise</a>
                      </li>
                      <li className="breadcrumb-item active">All Enterprise</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {loadingUpdate && <LoadingBox />}
                    {updateError ? (
                      <MessageBox variant="danger">{updateError}</MessageBox>) : null}
                    {loading ? (
                      <LoadingBox />
                    ) : error ? (
                      <MessageBox variant="danger">{error}</MessageBox>
                    ) : (
                      <div className="table-responsive-xl">
                        <MDBDataTableV5
                          hover
                          entriesOptions={[5, 20, 25, 50]}
                          entries={10}
                          pagesAmount={4}
                          data={datatable}
                          searchTop
                          searchBottom={false}
                          barReverse
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <>
          <div className="modal-header">
            <h5 className="modal-title">Edit Enterprise</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setOpen(false)}
            ></button>
          </div>
          <DialogContent>
            <form onSubmit={handleUpdate}>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label"
                  >
                    Enterprise Name
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enterprise Name"
                    id="example-text-input"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label"
                  >
                    What is the minimum duration(in days)?
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="What is the minimum duration?"
                    id="example-text-input"
                    onChange={(e) => setDuration(e.target.value)}
                    required
                    value={duration}
                  />
                </div>

                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      className="form-label"
                      forHtml="contactPersonName"
                    >
                      Product Category
                    </label>
                    <select
                      onChange={(e) =>
                        setProductCategory(e.target.value)
                      }
                      className="select2 form-select"
                      required
                      value={category}
                    >
                      {loadingCategory ? (
                        'Loading...'
                      ) : categorylist.length > 0 ? (
                        <>
                          <option>Select product category</option>
                          {categorylist.map((item) => (
                            <option value={item._id} key={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </>
                      ) : (
                        'there is no product category'
                      )}
                    </select>
                  </div>
                </div>

                <div className="col-lg-12 mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label"
                  >
                    Add the Economics of Production to this
                    Enterprise:
                  </label>
                  {list.map((x, i) => {
                    return (
                      <div key={i}>
                        <div className="row">
                          <div className="form-group col-md-10 mb-2">
                            <div className="card bg-soft-info">
                              <div className="card-body p-2">
                                <p className="m-0 p-1">
                                  Item {i + 1}
                                </p>
                              </div>
                            </div>
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              EOP Item/Activity
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="EOPItem"
                              placeholder="EOP Item/Activity"
                              id="example-text-input"
                              onChange={(e) =>
                                handleinputchange(
                                  {
                                    name: e.target.name,
                                    value: e.target.value,
                                  },
                                  i,
                                )
                              }
                              required
                              value={x.EOPItem}
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-10 mb-2">
                          <label
                            className="form-label"
                            forHtml="serviceCategory"
                          >
                            Service Category
                          </label>
                          {loadingServices ? (
                            'Loading...'
                          ) : services.length === 0 ? (
                            <div className='p-2 bg-soft-danger r-4'>No Service Category</div>
                          ) : (
                            <select
                              className="select2 form-select"
                              onChange={(opt) => handleinputchange(
                                {
                                  name: 'serviceCategory',
                                  value: opt.value,
                                },
                                i,
                              )}
                              required
                              name='serviceCategory'
                              value={x.serviceCategory}
                            >
                              <option value="">Select the option</option>
                              {services.map((opt) => (
                                <option value={opt._id} key={opt._id}>{opt.name}</option>
                              ))}
                            </select>
                          )}
                        </div>
                        {/* <div className="form-group col-md-10 mb-2">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-12 col-form-label"
                          >
                            Product
                          </label>
                          <Select
                            onChange={(opt) =>
                              handleinputchange(
                                {
                                  name: 'product',
                                  value: opt.map(o => o.value),
                                },
                                i,
                              )
                            }
                            getOptionValue={(option) => option.value}
                            required
                            name="product"
                            isMulti
                            options={
                              loadingProduct
                                ? []
                                : products.length === 0
                                  ? []
                                  : products.map((opt) => ({
                                    label: opt.name,
                                    value: opt._id,
                                  }))
                            }
                            value={x.product
                              ? x.product
                                .map((productId) =>
                                  products && products.find((product) => product._id === productId)
                                )
                                .filter((product) => product !== undefined)
                                .map((product) => ({
                                  label: product.name,
                                  value: product._id,
                                }))
                              : []}
                          />
                        </div> */}
                        <div className="row">
                          <div className="form-group col-md-5 mb-2">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Quantity
                            </label>
                            <input
                              className="form-control"
                              name="quantity"
                              type="number"
                              placeholder="Quantity"
                              id="example-text-input"
                              onChange={(e) =>
                                handleinputchange(
                                  {
                                    name: e.target.name,
                                    value: e.target.value,
                                  },
                                  i,
                                )
                              }
                              required
                              value={x.quantity}
                            />
                          </div>
                          <div className="form-group col-md-5 mb-2">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Unit of Measurement
                            </label>
                            <select
                              className="form-control form-select select2"
                              name="UoM"
                              placeholder="Unit of measurement"
                              id="example-text-input"
                              onChange={(e) =>
                                handleinputchange(
                                  {
                                    name: e.target.name,
                                    value: e.target.value,
                                  },
                                  i,
                                )
                              }
                              required
                              value={x.UoM}
                            >
                              <option>Select measuring unit</option>
                              <option value="Birds">Birds</option>
                              <option value="Box(es)">Box(es)</option>
                              <option value="Kg">kg</option>
                              <option value="Units">Units</option>
                              <option value="Animals">Animals</option>
                              <option value="Bags">Bags</option>
                              <option value="Tonnes">Tonnes</option>
                              <option value="Litres">Litres</option>
                              <option value="Items">Items</option>
                              <option value="Hectares">
                                Hectares
                              </option>
                              <option value="Acre">Acre</option>
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-8">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Notes
                            </label>
                            <textarea
                              type="text"
                              name="notes"
                              className="form-control"
                              onChange={(e) =>
                                handleinputchange(
                                  {
                                    name: e.target.name,
                                    value: e.target.value,
                                  },
                                  i,
                                )
                              }
                              placeholder="Notes"
                              value={x.notes}
                            />
                            <hr className="mt-4 mb-3 my-4" />
                          </div>
                          <div className="form-group col-md-4 mt-4">
                            {list.length !== 1 && (
                              <button
                                className="btn btn-danger mx-1"
                                type="button"
                                onClick={() => handleremove(i)}
                              >
                                -
                              </button>
                            )}
                            {list.length - 1 === i && (
                              <button
                                className="btn btn-success"
                                onClick={handleAddClick}
                              >
                                +
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <DialogActions className='mt-4 mb-4'>
                  <div className='row'>
                    <div className="col-lg-12">
                      <button
                        className="btn btn-sm btn-success flex-end"
                        type="submit"
                        onClick={handleUpdate}
                      >
                        Update
                      </button>
                    </div>
                  </div>

                </DialogActions>
              </div>
            </form>
          </DialogContent>
        </>
      </Dialog>
      {alert}
    </div>
  )
}
