import React, { useState } from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {
  IsLoggedInRoute,
  ManufacturerRoute,
  AdminRoute,
  PrivateRoute,
  ServiceProviderRoute
} from './components'
import {
  LoginPage,
  RecoverpwPage,
  RegisterPage,
  ProfilePage,
  EditProfilePage,
  VerifyOTPPage,
  StatusPage,
  ResetPasswordPage,
  OnboardingPage,
  WalletPage,
} from './pages/auth'
import { logout } from './actions/authActions'
import jwt from 'jsonwebtoken'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import DashboardPage from './pages/auth/DashboardPage'
import {
  TagGenerationPage,
  TagAnalysisPage,
  TagRequestsPage,
  TagOrderPage,
  TagStatusPage,
} from './pages/admin/Tag'
import {
  CreateManufacturerPage,
  AllManufacturerPage,
} from './pages/admin/Manufacturer'
import {
  AllProductPage,
  CreateProductPage,
  ProductCategoryPage,
  ProductAnalysisPage,
} from './pages/admin/Product'
import { ProductsPage } from './pages/manufacturer/Product'
import {
  NewTagRequestPage,
  LabelsOrdersPage,
  ActivateLabelsPage,
} from './pages/manufacturer/Labels'
import VerifyTagPage from './pages/farmer/verifyTag/VerifyTagPage'
import { FarmersPage, FarmerDetailPage } from './pages/admin/farmers'
import {
  BenefitPage,
  CreateBenefitPage,
  BenefitCategoryPage,
  ClaimPage,
  ClaimDetailPage,
} from './pages/admin/Benefits'
import { InboxPage, ReadEmailPage } from './pages/admin/Notification'
import {
  UserMessagePage,
  MessagePage,
  FarmGPTPage,
} from './pages/farmer/messages'
import {
  ManufacturerMessagePage,
  ManufacturerReadMessagePage,
} from './pages/manufacturer/Messages'
import {
  ServicePage,
  AddNewPage,
  ServiceCategoryPage,
  BenefitClaimPage,
  AllServicesPage,
} from './pages/admin/ServiceProviders'
import { AllUsersPage } from './pages/admin/Users'
import {
  AddServiceProviderPage,
  AllServiceProviderPage,
} from './pages/manufacturer/ServiceProviders'
import SweetAlert from 'react-bootstrap-sweetalert'
import AllPackagesPage from './pages/admin/Packages/AllPackagePage'
import {
  CreatePackagePage,
  EditPackagePage,
  PackageCategoryPage,
  PackageDetailPage,
} from './pages/admin/Packages/index'
import {
  AllFarmcyclePage,
  FarmCycleDetailPage,
  UserFarmCycleDetailPage,
  ViewFarmCyclePage,
} from './pages/admin/FarmType/index'
import {
  CreateFarmCyclePage,
  CycleDetailPage,
  FarmCyclePage,
} from './pages/farmer/farmcycle/index'
import LabelGenerationPage from './pages/admin/Tag/LabelGenerationPage'
import ApplicationPage from './pages/admin/YeapSafer/ApplicationPage'
import { AccountPage, LoanApplicationPage } from './pages/farmer/farmtrove'
import {
  AccountReportPage,
  WithdrawRequestPage,
  UserAccountReportPage,
  ViewLoanApplicationPage,
  LoanPage,
  LoanProviderPage,
  FundAllocationPage,
  UserWalletPage
} from './pages/admin/Farmtrove'
import { NewEnterpricePage, AllEnterprisePage } from './pages/admin/Enterprise'
import PasswordPage from './pages/auth/PasswordPage'
import { ProductServicePage, AddNewServicePage } from './pages/serviceproviders/products'
import { ProductOrdersPage, NewOrderPage, FarmerOrdersPage } from './pages/serviceproviders/orders'
import { OrdersPage } from './pages/manufacturer/orders'
import { AddClusterPage, AllClusterPage, ClusterCategoryPage } from './pages/admin/Farmcluster'
import { MyClustersPage } from './pages/manufacturer/Farmclusters'

function App() {
  const [alert, setAlert] = useState(false)

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const dispatch = useDispatch()
  if (userInfo) {
    let onlyToken = userInfo.token
    jwt.verify(onlyToken, 'Secret', (err) => {
      if (err) {
        setAlert(true)
        return dispatch(logout())
      }
      return
    })
  }

  return (
    <div className="App">
      <ToastContainer />

      <Router>
        <Switch>
          {/* =====================
          =========================
          =======Open Route=======
          =========================
          ========================*/}
          <Route path="/" exact={true} component={LoginPage} />
          <Route path="/verify-otp" exact={true} component={VerifyOTPPage} />
          <Route path="/auth-register" component={RegisterPage} />
          <Route path="/auth-recoverpassword" component={RecoverpwPage} />
          <Route path="/status" component={StatusPage} />
          <Route path="/password-reset" component={ResetPasswordPage} />
          <Route path="/yeap_safer" component={OnboardingPage} />
          {/* =====================
          =========================
          ======Default Route======
          =========================
          ========================*/}
          <IsLoggedInRoute path="/profile" component={ProfilePage} />
          <IsLoggedInRoute path="/passwords" component={PasswordPage} />
          <IsLoggedInRoute path="/edit-profile" component={EditProfilePage} />
          <IsLoggedInRoute path="/dashboard" component={DashboardPage} />
          <IsLoggedInRoute path="/farmtrove/wallet" component={WalletPage} />

          {/* =====================
          =========================
          =======Admin Route=======
          =========================
          ========================*/}
          <AdminRoute path="/tag-generation" component={TagGenerationPage} />
          <AdminRoute path="/tag-analysis" component={TagAnalysisPage} />
          <AdminRoute path="/tag-request" component={TagRequestsPage} />
          <AdminRoute path="/request/:id" component={TagOrderPage} />
          <AdminRoute path="/manufacturers" component={AllManufacturerPage} />
          <AdminRoute
            path="/create-manufacturer"
            component={CreateManufacturerPage}
          />
          <AdminRoute path="/products" component={AllProductPage} />
          <AdminRoute
            path="/product/analysis/:id"
            component={ProductAnalysisPage}
          />
          <AdminRoute path="/create-product" component={CreateProductPage} />
          <AdminRoute
            path="/product-category"
            component={ProductCategoryPage}
          />
          <AdminRoute path="/farmers" component={FarmersPage} />
          <AdminRoute path="/farmer/detail/:id" component={FarmerDetailPage} />
          <AdminRoute path="/benefit-status" component={TagStatusPage} />
          <AdminRoute path="/benefits" component={BenefitPage} />
          <AdminRoute
            path="/benefit-category"
            component={BenefitCategoryPage}
          />
          <AdminRoute path="/create-benefit" component={CreateBenefitPage} />
          <AdminRoute path="/claim-management" component={ClaimPage} />
          <AdminRoute path="/claim/detail/:id" component={ClaimDetailPage} />
          <AdminRoute path="/inbox" component={InboxPage} />
          <AdminRoute path="/message/:id" component={ReadEmailPage} />
          <AdminRoute path="/service-providers" component={ServicePage} />
          <AdminRoute path="/new-service-provider" component={AddNewPage} />
          <AdminRoute path="/services" component={AllServicesPage} />
          <AdminRoute
            path="/service-category"
            component={ServiceCategoryPage}
          />
          <AdminRoute path="/benefit-claims" component={BenefitClaimPage} />
          <AdminRoute path="/users" component={AllUsersPage} />
          <AdminRoute path="/create-protocol" component={CreatePackagePage} />
          <AdminRoute path="/protocols" component={AllPackagesPage} />
          <AdminRoute
            path="/protocol-category"
            component={PackageCategoryPage}
          />
          <AdminRoute
            path="/protocol/detail/:id"
            component={PackageDetailPage}
          />
          <AdminRoute path="/protocol/edit/:id" component={EditPackagePage} />
          <AdminRoute path="/all-farm-cycle" component={AllFarmcyclePage} />
          <AdminRoute
            path="/farm-cycle/detail/:id"
            component={FarmCycleDetailPage}
          />
          <AdminRoute
            path="/farm-cycle/user/:id"
            component={UserFarmCycleDetailPage}
          />
          <AdminRoute path="/farm-cycle/:id" component={ViewFarmCyclePage} />
          <AdminRoute path="/generate-label" component={LabelGenerationPage} />
          <AdminRoute path="/cluster/yeap-safer" component={ApplicationPage} />
          <AdminRoute
            path="/farmtrove/account-report"
            component={AccountReportPage}
          />
          <AdminRoute
            path="/farmtrove/user/account-report/:id"
            component={UserAccountReportPage}
          />
          <AdminRoute
            path="/farmtrove/withdraw-request"
            component={WithdrawRequestPage}
          />
          <AdminRoute path="/farmtrove/loan" component={LoanPage} />
          <AdminRoute
            path="/farmtrove/application/:id"
            component={ViewLoanApplicationPage}
          />
          <AdminRoute
            path="/farmtrove/loan-provider"
            component={LoanProviderPage}
          />
          <AdminRoute path="/farmtrove/fund-allocation" component={FundAllocationPage} />
          <AdminRoute path="/farmtrove/user-wallet/:id" component={UserWalletPage} />
          <AdminRoute path="/add-enterprice" component={NewEnterpricePage} />
          <AdminRoute path="/all-enterprice" component={AllEnterprisePage} />
          <AdminRoute path="/farmcluster/add-cluster" component={AddClusterPage} />
          <AdminRoute path="/farmcluster/all-clusters" component={AllClusterPage} />
          <AdminRoute path="/farmcluster/cluster-categories" component={ClusterCategoryPage} />



          {/* =====================
          =========================
          ===Manufacturer Route====
          =========================
          ========================*/}
          <ManufacturerRoute
            path="/manufacturer/products"
            component={ProductsPage}
          />
          <ManufacturerRoute
            path="/manufacturer/tag-request"
            component={LabelsOrdersPage}
          />
          <ManufacturerRoute
            path="/manufacturer/new-tag-request"
            component={NewTagRequestPage}
          />
          <ManufacturerRoute
            path="/manufacturer/activate-tag"
            component={ActivateLabelsPage}
          />
          <ManufacturerRoute
            path="/manufacturer/messages"
            component={ManufacturerMessagePage}
          />
          <ManufacturerRoute
            path="/manufacturer/message/:id"
            component={ManufacturerReadMessagePage}
          />
          <ManufacturerRoute
            path="/manufacturer/new-service-provider"
            component={AddServiceProviderPage}
          />
          <ManufacturerRoute
            path="/manufacturer/service-providers"
            component={AllServiceProviderPage}
          />
          <ManufacturerRoute
            path="/manufacturer/all-orders"
            component={OrdersPage}
          />

          <ManufacturerRoute
            path="/manufacturer/farmcluster/all-clusters"
            component={MyClustersPage}
          />


          {/* =====================
          =========================
          =====Farmer Route========
          =========================
          ========================*/}
          <PrivateRoute path="/user/activate-tag" component={VerifyTagPage} />
          <PrivateRoute path="/user/messages" component={UserMessagePage} />
          <PrivateRoute path="/user/message/:id" component={MessagePage} />
          <PrivateRoute
            path="/user/create-farm-cycle"
            component={CreateFarmCyclePage}
          />
          <PrivateRoute path="/user/all-farm-cycle" component={FarmCyclePage} />
          <PrivateRoute
            path="/user/farm-cycle/detail/:id"
            component={CycleDetailPage}
          />
          <PrivateRoute path="/farmtrove/account" component={AccountPage} />
          <PrivateRoute
            path="/farmtrove/loan-application"
            component={LoanApplicationPage}
          />
          <PrivateRoute path="/user/farmgpt" component={FarmGPTPage} />

          {/* =====================
          =========================
          =Service Provider Route==
          =========================
          ========================*/}
          <ServiceProviderRoute path="/service/add-service" component={AddNewServicePage} />
          <ServiceProviderRoute path="/service/all-services" component={ProductServicePage} />
          <ServiceProviderRoute path="/service/product-orders" component={ProductOrdersPage} />
          <ServiceProviderRoute path="/service/new-order" component={NewOrderPage} />
          <ServiceProviderRoute path="/service/all-orders" component={FarmerOrdersPage} />


        </Switch>
      </Router>

      {alert ? (
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Session Expired"
          onConfirm={() => setAlert(false)}
          onCancel={() => setAlert(false)}
        >
          Your session has expired. Please log in again
        </SweetAlert>
      ) : null}
    </div>
  )
}

export default App
