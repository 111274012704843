import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import userSigninReducer from './reducers/authReducers/loginReducer'
import {
  userDetailReducer,
  userUpdateReducer,
} from './reducers/authReducers/ProfileReducers'
import userRegisterReducer from './reducers/authReducers/RegisterReducers'
import {
  tagGenerationReducer,
  batchListReducer,
  tagsDownloadReducer,
  tagsRequestsReducer,
  tagsRequestDeatilReducer,
  batchNotAssignedListReducer,
  assignBatchReducer,
  tagsUpdateOrderReducer,
  tagOrderDeleteReducer,
  tagStatusValidateReducer,
  sendInvoiceReducer,
  tagsPDFDownloadReducer,
} from './reducers/adminReducers/tagsReducers/tagReducer'
import {
  addManufacturerReducer,
  deleteManufacturerReducer,
  manufacturersListReducer,
  updateManufacturerReducer,
} from './reducers/adminReducers/manufacturersReducers/manufacturerRedcer'
import {
  addProductCategoryReducer,
  addProductReducer,
  assignedPCPointReducer,
  assignProductCategoryPointReducer,
  deleteProductCategoryReducer,
  deleteProductReducer,
  organisationProductsReducer,
  productCategoryListReducer,
  productDetailsReducer,
  productListReducer,
  updateProductCategoryReducer,
  updateProductReducer,
} from './reducers/adminReducers/productReducers/productReducer'
import {
  editProductReducer,
  manufacturerProductListReducer,
} from './reducers/manufacturer/productReducers/productsReducer'
import {
  manufacturerOrderListReducer,
  newOrderReducer,
  serviceProviderOrderReducer,
  updateProviderOrderReducer,
} from './reducers/manufacturer/labelsReducers/ordersReducer'
import { manufacturerActivateTagReducer } from './reducers/manufacturer/labelsReducers/activateTagReducer'
import {
  resetPasswordReducer,
  resetTransactionPINReducer,
  resetUserPasswordReducer,
  userResetPasswordReducer,
} from './reducers/authReducers/PasswordResetReducers'
import {
  farmerTagReducer,
  farmerTagStatusReducer,
  farmerTagValidateReducer,
} from './reducers/farmerReducer/tagReducer/tagReducer'
import {
  farmerActivitiesReducer,
  scanActivitiesReducer,
} from './reducers/farmerReducer/activitiesReducer/activitiesReducer'
import {
  deleteFarmerReducer,
  farmerDetailReducer,
  farmersListReducer,
  farmersScanReducer,
} from './reducers/adminReducers/farmerReducers/index'
import { farmerClaimBenefitReducer } from './reducers/farmerReducer/claimReducer/claimReducer'
import {
  addBenefitCategoryReducer,
  allBenefitsReducer,
  allClaimReducer,
  benefitCategoryDeleteReducer,
  benefitCategoryListReducer,
  benefitDeleteReducer,
  benefitsCreateReducer,
  claimDetailReducer,
  deleteClaimReducer,
  updateBenefitCategoryReducer,
  updateBenefitReducer,
  updateClaimReducer,
} from './reducers/adminReducers/benefitReducers/index'
import {
  userSendOtpReducer,
  userVerifyOtpReducer,
} from './reducers/authReducers/otpReducer'
import {
  dashboardReducer,
  dashboardTagAnalysisReducer,
} from './reducers/adminReducers/dashboardReducers/index'
import {
  manufacturerDashboardReducer,
  manufacturerProductAnalysisReducer,
} from './reducers/manufacturer/dashboardReducers/index'
import {
  adminReadMessageReducer,
  messageListReducer,
  sendMessageReducer,
} from './reducers/adminReducers/notificationReducers/notificationReducers'
import {
  usersListReducer,
  updateUserReducer,
  adminResetUserPasswordReducer,
} from './reducers/adminReducers/userReducers/index'
import {
  farmGPTMessageReducer,
  getFarmGPTMessageReducer,
  userMessagesReducer,
  userReadMessageReducer,
  userSendMessageReducer,
} from './reducers/farmerReducer/messageReducer/messageReducer'
import {
  manufacturerMessagesReducer,
  manufacturerReadMessageReducer,
  manufacturerSendMessageReducer,
} from './reducers/manufacturer/messageReducer/messageReducer'
import { statusValidateReducer } from './reducers/authReducers/statusReducer'
import { reportSubmissionReducer } from './reducers/authReducers/reportReducer'
import {
  addServiceCategoryReducer,
  allBenefitClaimsReducer,
  allOrganisationServiceCategoryReducer,
  allServiceReducer,
  assignServiceCategoryReducer,
  organisationServiceCategoryDeleteReducer,
  serviceCategoryDeleteReducer,
  serviceCategoryListReducer,
  serviceClaimDeleteReducer,
  serviceCreateReducer,
  serviceProviderDeleteReducer,
  updateAssignedServiceCategoryReducer,
  updateServiceCategoryReducer,
  updateServiceProviderReducer,
} from './reducers/adminReducers/serviceReducers/index'
import { servicesListReducer } from './reducers/authReducers/servicesReducer'
import { serviceProviderListReducers } from './reducers/farmerReducer/serviceProvidersReducers/serviceProviderReducers'
import {
  allServiceProvidersReducer,
  organisationAddServiceProducerReducer,
  organisationServiceCategoryReducer,
  organisationUpdateServiceProducerReducer,
} from './reducers/manufacturer/serviceProviderReducers/index'
import {
  addPackagesCategoryReducer,
  allPackagesReducer,
  packageCategoryDeleteReducer,
  packageCategoryListReducer,
  packageCreateReducer,
  packageDeleteReducer,
  packageDetailsReducer,
  updatePackagesCategoryReducer,
  updatePackageDetailReducer,
  updatePackageActivityReducer,
  updatePackageBenefitReducer,
} from './reducers/adminReducers/packageReducers/index'
import { activityReducer } from './reducers/adminReducers/activityReducers/index'
import {
  aiCycleReviewReducer,
  aiLearnMoresReducer,
  allCycleReducer,
  complianceTagReducer,
  cycleDetailReducer,
  newCycleReducer,
  packageListReducer,
  postponeCycleReducer,
  saveComplianceReducer,
  startCycleReducer,
  updateEOPReducer,
  weatherAnalysisReducer,
} from './reducers/farmerReducer/cycleReducer/index'
import {
  allFarmCyclesReducer,
  packageCycleDetailReducer,
  userFarmCycleDetailReducer,
  userScanActivitiesReducer,
  viewFarmCycleDetailReducer,
} from './reducers/adminReducers/cycleReducers/index'
import applicationReducer from './reducers/authReducers/applicationReducer'
import {
  allApplicationsReducer,
  saveDateReducer,
} from './reducers/adminReducers/applicationReducers/index'
import {
  accountIncomeReducer,
  accountInformationReducer,
  accountTransactionsReducer,
  exchangeTokenReducer,
} from './reducers/farmerReducer/farmtroveReducer/accountReducer'
import {
  verifyWalletFundReducer,
  walletBalanceReducer,
  walletFundReducer,
  sendFundReducer,
  walletTransactionReducer,
  verifyUserIDReducer,
  verifyAccountReducer,
  withdrawFundReducer,
  escrowTransferReducer,
  secureTransfersReducer,
  claimTransfersReducer,
  confirmServiceReducer,
  validateServiceReducer,
  verifyCodeReducer,
} from './reducers/authReducers/walletReducer'
import {
  userAccountInformationReducer,
  userAccountTransactionsReducer,
  userAccountIncomeReducer,
  userIdentityReducer,
  allWithdrawRequestReducer,
  updateWithdrawRequestReducer,
  fundAccountReducer,
} from './reducers/adminReducers/farmtroveReducers/accountReducer'
import {
  acceptLoanOfferReducer,
  allLoansProviderReducer,
  allLoansReducer,
  loanApplicationReducer,
  loanRepaymentReducer,
  verifyIdentityReducer,
} from './reducers/farmerReducer/farmtroveReducer/loanReducer'
import {
  allUserLoansReducer,
  loanCreateReducer,
  allLoansTypeReducer,
  loanApplicationDetailReducer,
  loanRiskCheckReducer,
  updateLoanApplicationReducer,
  updateLoanReducer,
} from './reducers/adminReducers/farmtroveReducers/loanReducer'
import {
  allEnterpriseReducer,
  enterpriseCreateReducer,
  enterpriseUpdateReducer,
} from './reducers/adminReducers/enterpriseReducers/index'
import { enterpriseListReducer } from './reducers/farmerReducer/farmtroveReducer/enterpriseReducer'
import { farmerProductListReducer, farmerProductServiceListReducer } from './reducers/farmerReducer/productReducer/productReducer'
import { fundAllocationHistoryReducer, fundAllocationReducer, mainWalletBalanceReducer } from './reducers/adminReducers/farmtroveReducers/fundAllocationReducer'
import { userWalletBalanceReducer, userWalletTransactionReducer } from './reducers/adminReducers/farmtroveReducers/walletReducer'
import { adminSendOtpReducer, adminVerifyOtpReducer } from './reducers/adminReducers/farmtroveReducers/verifyAdminReducer'
import { serviceProductListReducer } from './reducers/serviceReducers/productsReducers/productReducers'
import { allServiceProductReducer, createServiceReducer, editServiceProductReducer } from './reducers/serviceReducers/productsReducers/serviceReducers'
import { farmersOrdersReducer, serviceProviderOrdersReducer, serviceProviderplaceOrderReducer, updatefarmerOrderReducer } from './reducers/serviceReducers/orderReducers/orderReducer'
import { addClusterCategoryReducer, addClusterReducer, clusterCategoryDeleteReducer, clusterCategoryListReducer, clusterDeleteReducer, clusterListReducer, updateClusterCategoryReducer, updateClusterReducer } from './reducers/adminReducers/farmclusterReducers/index'
import { myClustersReducer, requestQuoteReducer } from './reducers/manufacturer/farmclusterReducers/index'
import { userClusterCategoryListReducer, userClusterListReducer } from './reducers/authReducers/farmclusterReducer'

const initialState = {
  userSignin: {
    userInfo: localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : null,
  },
}

const reducer = combineReducers({
  // Auth
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  userDetail: userDetailReducer,
  passwordReset: userResetPasswordReducer,
  userPasswordReset: resetUserPasswordReducer,
  updatePassword: resetPasswordReducer,
  userProfileUpdate: userUpdateReducer,
  userOtpRequest: userSendOtpReducer,
  userVerify: userVerifyOtpReducer,
  validateStatus: statusValidateReducer,
  listAllServices: servicesListReducer,
  applicationSubmission: applicationReducer,
  serviceConfirm: confirmServiceReducer,
  validateService: validateServiceReducer,
  verifiedCode: verifyCodeReducer,
  clusterList: userClusterListReducer,
  clusterCategory: userClusterCategoryListReducer,

  // Admin
  tagsCreation: tagGenerationReducer,
  allBatchs: batchListReducer,
  notAssignedBatchs: batchNotAssignedListReducer,
  assign: assignBatchReducer,
  tagRequestUpdate: tagsUpdateOrderReducer,
  deleteOrder: tagOrderDeleteReducer,
  downloadTag: tagsDownloadReducer,
  downloadPDF: tagsPDFDownloadReducer,
  manufacturersList: manufacturersListReducer,
  newManufacturer: addManufacturerReducer,
  manufacturerDelete: deleteManufacturerReducer,
  manufacturerUpdate: updateManufacturerReducer,
  productsList: productListReducer,
  adminProductAnalysis: productDetailsReducer,
  newProductCategory: addProductCategoryReducer,
  productUpdate: updateProductReducer,
  productDelete: deleteProductReducer,
  productCategory: productCategoryListReducer,
  tagsRequests: tagsRequestsReducer,
  tagRequestDetail: tagsRequestDeatilReducer,
  farmersList: farmersListReducer,
  farmersInfo: farmerDetailReducer,
  farmerDelete: deleteFarmerReducer,
  updateCategory: updateProductCategoryReducer,
  deleteCategory: deleteProductCategoryReducer,
  benefitsCreate: benefitsCreateReducer,
  benefitsList: allBenefitsReducer,
  adminBenefitDelete: benefitDeleteReducer,
  benefitCategory: benefitCategoryListReducer,
  updateBenefit: updateBenefitReducer,
  addBenefitCategory: addBenefitCategoryReducer,
  updateBenefitCategory: updateBenefitCategoryReducer,
  deleteBenefitCategory: benefitCategoryDeleteReducer,
  statusValidation: tagStatusValidateReducer,
  dashboardAnalysis: dashboardReducer,
  dashboardTagAnalysis: dashboardTagAnalysisReducer,
  claimList: allClaimReducer,
  detailOfClaim: claimDetailReducer,
  updateClaim: updateClaimReducer,
  claimDelete: deleteClaimReducer,
  adminListNotification: messageListReducer,
  readMessage: adminReadMessageReducer,
  usersList: usersListReducer,
  userUpdate: updateUserReducer,
  adminResetPassword: adminResetUserPasswordReducer,
  sendNotificaiton: sendMessageReducer,
  sendInvoice: sendInvoiceReducer,
  sentReport: reportSubmissionReducer,
  serviceCreate: serviceCreateReducer,
  serviceList: allServiceReducer,
  updateService: updateServiceProviderReducer,
  deletedService: serviceProviderDeleteReducer,
  serviceCategory: serviceCategoryListReducer,
  addServiceCategory: addServiceCategoryReducer,
  deteleServiceCategory: serviceCategoryDeleteReducer,
  updateServiceCategory: updateServiceCategoryReducer,
  benefitClaims: allBenefitClaimsReducer,
  claimServiceDelete: serviceClaimDeleteReducer,
  assignedCategory: assignServiceCategoryReducer,
  organsationCategories: allOrganisationServiceCategoryReducer,
  updateAssigned: updateAssignedServiceCategoryReducer,
  deleteAssigned: organisationServiceCategoryDeleteReducer,
  allpackages: allPackagesReducer,
  packageDelete: packageDeleteReducer,
  packageCategory: addPackagesCategoryReducer,
  packageCategoryList: packageCategoryListReducer,
  deletePackageCategory: packageCategoryDeleteReducer,
  updatePackageCategory: updatePackagesCategoryReducer,
  compliance: activityReducer,
  packageCreate: packageCreateReducer,
  organisationProductList: organisationProductsReducer,
  viewPackageDetail: packageDetailsReducer,
  productCategoryPoint: assignProductCategoryPointReducer,
  allProductCategoryPoint: assignedPCPointReducer,
  cycleList: allFarmCyclesReducer,
  cycleDetail: packageCycleDetailReducer,
  userfarmcycle: userFarmCycleDetailReducer,
  viewCycle: viewFarmCycleDetailReducer,
  scanActivities: userScanActivitiesReducer,
  farmerScanActivities: farmersScanReducer,
  applicationEntries: allApplicationsReducer,
  applicationOpenDate: saveDateReducer,
  userIncomeReport: userAccountIncomeReducer,
  userAccountReport: userAccountInformationReducer,
  userTransactionsReport: userAccountTransactionsReducer,
  userIdentityReport: userIdentityReducer,
  withdrawRequests: allWithdrawRequestReducer,
  updateWithdrawStatus: updateWithdrawRequestReducer,
  userLoans: allUserLoansReducer,
  addLoan: loanCreateReducer,
  listAllLoans: allLoansTypeReducer,
  loanDetail: loanApplicationDetailReducer,
  loanRisk: loanRiskCheckReducer,
  loanUpdate: updateLoanApplicationReducer,
  allEnterprise: allEnterpriseReducer,
  enterpriseCreate: enterpriseCreateReducer,
  packageDetailUpdate: updatePackageDetailReducer,
  packageActivityUpdate: updatePackageActivityReducer,
  packageBenefitUpdate: updatePackageBenefitReducer,
  fundUser: fundAllocationReducer,
  fundUserHistory: fundAllocationHistoryReducer,
  userWalletBalance: userWalletBalanceReducer,
  userTransactions: userWalletTransactionReducer,
  updateLoanProvider: updateLoanReducer,
  enterpriseUpdated: enterpriseUpdateReducer,
  sendOtp: adminSendOtpReducer,
  verifyOtp: adminVerifyOtpReducer,
  fundMain: fundAccountReducer,
  adminWalletBalance: mainWalletBalanceReducer,
  newCluster: addClusterReducer,
  listCluster: clusterListReducer,
  newClusterCategory: addClusterCategoryReducer,
  clusterCategoryList: clusterCategoryListReducer,
  updateClusterCategory: updateClusterCategoryReducer,
  deleteClusterCategory: clusterCategoryDeleteReducer,
  updateCluster: updateClusterReducer,
  removeCluster: clusterDeleteReducer,


  // Manufacturer
  newProduct: addProductReducer,
  manufacturerProductList: manufacturerProductListReducer,
  newOrder: newOrderReducer,
  manufacturerOrdersList: manufacturerOrderListReducer,
  activateTag: manufacturerActivateTagReducer,
  manufacturerDashboardAnalysis: manufacturerDashboardReducer,
  productAnalysis: manufacturerProductAnalysisReducer,
  manufacturerMessagesList: manufacturerMessagesReducer,
  readManufacturerMessage: manufacturerReadMessageReducer,
  manufacturerSendMessage: manufacturerSendMessageReducer,
  serviceProviders: allServiceProvidersReducer,
  organisationCategory: organisationServiceCategoryReducer,
  newServiceProvider: organisationAddServiceProducerReducer,
  updateServiceProvider: organisationUpdateServiceProducerReducer,
  manufacturerEditProduct: editProductReducer,
  serviceProviderProductOrder: serviceProviderOrderReducer,
  myClusterList: myClustersReducer,
  quoteRequested: requestQuoteReducer,
  updatedOrder: updateProviderOrderReducer,


  // farmer
  farmerScan: farmerTagReducer,
  farmValidate: farmerTagValidateReducer,
  activities: farmerActivitiesReducer,
  cycleScanActivities: scanActivitiesReducer,
  tagStatus: farmerTagStatusReducer,
  benefitClaim: farmerClaimBenefitReducer,
  userMessages: userMessagesReducer,
  readUserMessage: userReadMessageReducer,
  sendMessage: userSendMessageReducer,
  listProviders: serviceProviderListReducers,
  listPackages: packageListReducer,
  addCycle: newCycleReducer,
  listCycles: allCycleReducer,
  farmcycleDetail: cycleDetailReducer,
  activityCompliance: saveComplianceReducer,
  scanResult: complianceTagReducer,
  cycleStarts: startCycleReducer,
  cyclePostpone: postponeCycleReducer,
  analysisWeather: weatherAnalysisReducer,
  analysisActivity: aiLearnMoresReducer,
  aiChat: farmGPTMessageReducer,
  aiChatHistory: getFarmGPTMessageReducer,
  aiCycleReview: aiCycleReviewReducer,
  accountDetail: accountInformationReducer,
  accountHistory: accountTransactionsReducer,
  incomeAnalysis: accountIncomeReducer,
  tokenExchanged: exchangeTokenReducer,
  walletBalance: walletBalanceReducer,
  walletFund: walletFundReducer,
  verifyFund: verifyWalletFundReducer,
  sendFunds: sendFundReducer,
  walletTransactions: walletTransactionReducer,
  verifyId: verifyUserIDReducer,
  verifyAccount: verifyAccountReducer,
  withdrawFund: withdrawFundReducer,
  verify: verifyIdentityReducer,
  applyLoan: loanApplicationReducer,
  allLoans: allLoansReducer,
  loanProviders: allLoansProviderReducer,
  loanOfferStatus: acceptLoanOfferReducer,
  resetTransactionPin: resetTransactionPINReducer,
  enterpriseList: enterpriseListReducer,
  productList: farmerProductListReducer,
  EOPUpdate: updateEOPReducer,
  repayLoan: loanRepaymentReducer,
  productServiceList: farmerProductServiceListReducer,




  // service
  serviceProducts: serviceProductListReducer,
  newService: createServiceReducer,
  allServices: allServiceProductReducer,
  editServiceProduct: editServiceProductReducer,
  servicePlaceOrder: serviceProviderplaceOrderReducer,
  serviceProviderAllOrders: serviceProviderOrdersReducer,
  farmerOrders: farmersOrdersReducer,
  farmerUpdateOrder: updatefarmerOrderReducer,
  newEscrowTransfer: escrowTransferReducer,
  secureTransfers: secureTransfersReducer,
  claimFunds: claimTransfersReducer,
})

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk)),
)

export default store
